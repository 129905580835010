body {
  padding: 50px;
  font-family: sans-serif;
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url(./components/TaskList/TaskListItem/images/Completed.svg)
    url(./components/TaskList/TaskListItem/images/Incomplete.svg)
    url(./components/TaskList/TaskListItem/images/Locked.svg);
}

ul li {
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  body {
    padding: 5vw;
    font-family: sans-serif;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  body {
    padding: 5vw;
    font-family: sans-serif;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  body {
    padding: 5vw;
    font-family: sans-serif;
  }
}

.taskGroupMain {
  width: 400px;
}

.taskGroupMain .header span {
  font-size: 22px;
  font-weight: 400;
}

.taskGroupMain h1:after {
  content: "";
  display: block;
  height: 1px;
  margin: 20px 0px 20px;
  background: #9b9b9b;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .taskGroupMain {
    width: 85vw;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .taskGroupMain {
    width: 85vw;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .taskGroupMain {
    width: 85vw;
  }
}
